import axios from 'axios'
import urlJoin from 'url-join'
import download from 'downloadjs'
import { backOff } from 'exponential-backoff'

import getDownloadFileName from './getDownloadFileName'

const getPreviewImageURL = (code) => (
  urlJoin(process.env.INTRO_API_URL, `api/the-last-of-us/image/${code}.png`)
)

const downloadScreenshotFromApi = async (intro, useLocationHref) => {
  const { code } = intro
  const filename = getDownloadFileName(intro)
  const previewURL = getPreviewImageURL(code)

  if (useLocationHref) {
    // Aparently, Chrome on iOS cannot download blobs through downloadjs, so we
    // force the download by redirecting the user to the previewURL page

    // This one takes long time on the same page without feedback
    // window.location.href = previewURL

    // An alternative is force to open new tab to wait for the image to load
    window.open(previewURL, '_blank')
    return
  }

  const response = await backOff(() => axios.request({
    url: previewURL,
    responseType: 'blob',
  }))
  download(response.data, filename, 'image/png')
}

export default downloadScreenshotFromApi
