import React from 'react'

import ExternalLink from './ExternalLink'

const APP = 'the-last-of-us'

export default function NavbarMenu() {
  return (
    <ul className="menu">
      <li><a href="/#/">HOME</a></li>
      <li>
        <ExternalLink href={`https://help.kassellabs.io/${APP}/`}>CONTACT</ExternalLink>
      </li>
      <li>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>HELP</a>
        {' '}
        <ul className="dropdown">
          <li>
            <ExternalLink href={`https://help.kassellabs.io/${APP}/`}>
              FAQ
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href={`https://help.kassellabs.io/${APP}/#termsOfService`}>
              TERMS OF SERVICE
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://help.kassellabs.io/privacy/">
              PRIVACY POLICY
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://kassellabs.io/about">
              ABOUT US
            </ExternalLink>
          </li>
        </ul>
      </li>
      <li>
        <ExternalLink href="https://kassellabs.io">
          MORE APPS
        </ExternalLink>
      </li>
      <style jsx global>
        {`
        $tablet = 'only screen and (min-device-width : 768px)';

        .menu {
          --link-background-color-hover: #2f3036;
          --link-background-color-submenu: darken(#2f3036, 25%);

          margin: 0;
          padding: 0;
          list-style-type:none;

          .dropdown {
            margin: 0;
            padding: 0;
            list-style-type:none;
          }

          /*Display the dropdown on hover*/
          li a:hover + .dropdown, .dropdown:hover {
            display: flex;
            flex-direction: column;
          }

          li {
            display:inline-block;
            margin-right: 1px;
            cursor: pointer;

            width: 100%;

            @media $tablet {
              width: auto;
            }

            a {
              display: block;
              min-width: 170px;
              height: 50px;
              text-align: center;
              line-height: 50px;
              color: white;

              color: $linkColor;
              text-decoration: none;
            }

            &:hover {
              a {
                background: var(--link-background-color-hover);
              }

              .dropdown {
                a {
                  background-color: var(--link-background-color-submenu);
                  height: 40px;
                  line-height: 40px;

                  &:hover {
                    background-color: var(--link-background-color-hover);
                  }
                }
              }
            }

            .dropdown {
              z-index: 2;
              display: none;

              @media $tablet {
                position: absolute;
              }
            }
          }
        }
        `}
      </style>
    </ul>
  )
}
