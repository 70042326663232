import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Formik, useFormikContext } from 'formik'
import { debounce } from 'lodash'

import TextInputField from './TextInputField'

// TODO improve this by listening with ref on formik instead an inside component
const OnChangeListener = ({ onChange }) => {
  const { values, handleSubmit } = useFormikContext()

  const debouncedSubmit = useMemo(() => debounce(handleSubmit, 1000), [handleSubmit])

  useEffect(() => {
    onChange(values)
    debouncedSubmit()
  }, [values, debouncedSubmit])

  return null
}

OnChangeListener.propTypes = {
  onChange: PropTypes.func.isRequired,
}

const TEXT_INDEXES_ARRAY = [...Array(20).keys()]

export default function IntroForm({ initialValues, onSubmit, onChange }) {
  const textInputs = useMemo(() => (
    TEXT_INDEXES_ARRAY.map((index) => (
      <TextInputField
        key={index}
        name={`titles[${index}]`}
        label={`Title #${index + 1}`}
        maxRows={2}
        inputProps={{
          sx: {
            fontFamily: 'Press Gothic',
            fontWeight: '400',
          },
        }}
      />
    ))
  ))

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleChange={onChange}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <OnChangeListener onChange={onChange} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <Box
              display="grid"
              width="100%"
              gap={1.5}
              sx={{
                gridTemplateRows: {
                  sm: '1fr',
                  md: '1fr 1fr 1fr 1fr',
                },
                gridTemplateColumns: {
                  sm: '1fr',
                  md: '1fr 1fr 1fr 1fr',
                },
              }}
            >
              { textInputs }
            </Box>
            <Box
              sx={{
                maxWidth: {
                  sm: '100%',
                  md: '300px',
                },
                width: '100%',
              }}
            >
              <TextInputField
                name="endingTitle"
                label="Ending Title"
                multiline
                rows={3}
                inputProps={{
                  sx: {
                    textAlign: 'center',
                    fontFamily: 'Press Gothic',
                    fontWeight: '400',
                    fontSize: '1.4em',
                  },
                }}
              />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}

IntroForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
