import React from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@mui/material'
import { useField } from 'formik'

export default function TextInputField({
  label,
  name,
  required,
  maxLength,
  multiline,
  rows,
  maxRows,
  inputProps,
  ...props
}) {
  const [field] = useField({
    name,
    ...props,
  })

  return (
    <TextField
      id={name}
      label={label}
      variant="outlined"
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      value={field.value}
      onChange={field.onChange}
      required={required}
      multiline
      rows={rows}
      maxRows={maxRows}
      inputProps={{
        maxLength,
        ...inputProps,
      }}
    />
  )
}

TextInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  inputProps: PropTypes.object,
  maxLength: PropTypes.number,
}
