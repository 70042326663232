import React from 'react'
import PropTypes from 'prop-types'
import { Box, Link, Button } from '@mui/material'

import Modal from './Modal'

export default function ThankYouModal({ open, onClose }) {
  return (
    <Modal
      title="Payment completed successfully!"
      open={open}
      onClose={onClose}
    >
      <Box px={3} textAlign="center">
        <p>
          Thank you very much for supporting us!
        </p>
        <Box display="flex" justifyContent="center">
          <img src="/images/payment-confirmation-thanks.gif" alt="Today we cook" height="300" />
        </Box>
        <p>
          We have received your order and will start working on it!
        </p>
        <p>
          <b>
            It should take up to 20 minutes until your video is finished and it
            will be delivered to your email address.
          </b>
        </p>
        <p>
          If you have any questions or concerns, you can email us at:&nbsp;
          <br />
          <Link
            href="mailto:thelastofus@kassellabs.io?subject=I have a question about my The Last of Us Intro"
            variant="link"
          >
            thelastofus@kassellabs.io
          </Link>
        </p>
      </Box>
      <Box display="flex" justifyContent="center" px={3} py={2}>
        <Button
          variant="contained"
          onClick={onClose}
        >
          Ok
        </Button>
      </Box>
    </Modal>
  )
}

ThankYouModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
