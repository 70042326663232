import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import * as Sentry from '@sentry/nextjs'

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import hasTextChanged from '../hasTextChanged'
import DownloadVideoModal from './DownloadVideoModal'
import PaymentModal from './PaymentModal'
import ThankYouModal from './ThankYouModal'
import RenderedModal from './RenderedModal'
import InvalidTextModal from './InvalidTextModal'

const DOWNLOAD_MODAL_STRING = 'download'
const PAYMENT_MODAL_STRING = 'payment'
const THANK_YOU_MODAL_STRING = 'thank-you'
const RENDERED_MODAL_STRING = 'rendered'

const isEmptyEndingTitle = (intro) => !intro.endingTitle.trim()

const isEmptyTitles = (intro) => {
  const filledTitles = intro.titles.filter((title) => Boolean(title.trim()))
  return filledTitles.length < 2
}

export default function DownloadVideoButton({
  intro, initiallyOpened, currentFormValues, alertTextChanged,
}) {
  const [openModal, setOpenModal] = useState(null)
  const [invalidTextModalError, setInvalidTextModalError] = useState(null)
  // Store open state in a separate state to avoid showing the modal as empty
  // when closing it
  const [invalidTextModalOpen, setInvalidTextModalOpen] = useState(false)

  const isDownloadModalOpen = openModal === DOWNLOAD_MODAL_STRING
  const isPaymentModalOpen = openModal === PAYMENT_MODAL_STRING
  const isThankYouModalOpen = openModal === THANK_YOU_MODAL_STRING
  const isRenderedModalOpen = openModal === RENDERED_MODAL_STRING

  const onDownloadVideoClick = useCallback(() => {
    if (hasTextChanged(currentFormValues, intro)) {
      alertTextChanged()
      return
    }

    if (isEmptyEndingTitle(intro)) {
      setInvalidTextModalError('You must fill the Ending Title to download your intro')
      setInvalidTextModalOpen(true)
      return
    }

    if (isEmptyTitles(intro)) {
      setInvalidTextModalError('You must fill at least two titles to download your intro')
      setInvalidTextModalOpen(true)
      return
    }

    if (intro.status === 'created') {
      setOpenModal(DOWNLOAD_MODAL_STRING)
      return
    }

    if (intro.status === 'paid') {
      setOpenModal(THANK_YOU_MODAL_STRING)
      return
    }

    if (intro.status === 'rendered') {
      setOpenModal(RENDERED_MODAL_STRING)
    }
  }, [intro, currentFormValues, alertTextChanged])

  useEffect(() => {
    if (initiallyOpened) {
      onDownloadVideoClick()
    }
  }, [])

  return (
    <>
      <Button
        variant="contained"
        onClick={onDownloadVideoClick}
        startIcon={<OndemandVideoIcon />}
        size="large"
      >
        Download MP4 Video
      </Button>
      <DownloadVideoModal
        open={isDownloadModalOpen}
        onClose={() => setOpenModal(null)}
        onConfirm={() => setOpenModal(PAYMENT_MODAL_STRING)}
      />
      <PaymentModal
        code={intro.code}
        open={isPaymentModalOpen}
        onClose={() => setOpenModal(null)}
        onPaymentComplete={(payload) => {
          Sentry.setUser({ email: payload.email })
          setOpenModal(THANK_YOU_MODAL_STRING)

          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'purchase',
              value: payload.finalAmount,
              currency: payload.currency,
            })
          }
        }}
      />
      <ThankYouModal
        open={isThankYouModalOpen}
        onClose={() => setOpenModal(null)}
      />
      <RenderedModal
        intro={intro}
        open={isRenderedModalOpen}
        onClose={() => setOpenModal(null)}
      />
      <InvalidTextModal
        open={invalidTextModalOpen}
        error={invalidTextModalError}
        onClose={() => setInvalidTextModalOpen(false)}
      />
    </>
  )
}

DownloadVideoButton.propTypes = {
  intro: PropTypes.object,
  currentFormValues: PropTypes.object,
  initiallyOpened: PropTypes.bool,
  alertTextChanged: PropTypes.func,
}
