import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import Modal from './Modal'
import ExternalLink from '../layout/ExternalLink'
import FacebookIconLink from './FacebookIconLink'
import InstagramIconLink from './InstagramIconLink'
import TwitterIconLink from './TwitterIconLink'
import Button from '../Button'

export default function RenderedModal({ intro, open, onClose }) {
  return (
    <Modal
      title="Download"
      open={open}
      onClose={onClose}
      size="lg"
    >
      <Box p={3} textAlign="center">
        <p style={{ margin: 0 }}>
          Your video is ready to download!
        </p>
        <p style={{ margin: 0 }}>
          Click on the button below to download it!
        </p>
        <Box display="flex" justifyContent="center" py={2}>
          <Button
            variant="contained"
            color="primary"
            Component="a"
            rel="noopener noreferrer"
            href={intro?.video?.url}
            size="large"
          >
            Download Video
          </Button>
        </Box>
        <Box display="flex" justifyContent="center">
          <img className="rendered-gif" src="/images/rendered-image.gif" alt="Your video is finally here" />
        </Box>
        <p>
          If you have any problem while trying to download the video, please contact us:
          {' '}
          <ExternalLink href="https://help.kassellabs.io/the-last-of-us/" variant="link">
            FAQ and Contact
          </ExternalLink>
          .
        </p>
        <p>
          Don&apos;t forget to follow us on social media below to get our
          latest updates. If you like the video, please write us a review on
          our Facebook page. It would help us a lot!
        </p>
        <Box display="flex" justifyContent="center" gap="0.5em">
          <FacebookIconLink />
          <InstagramIconLink />
          <TwitterIconLink />
        </Box>
      </Box>
      <style jsx>
        {`
          :global(.rendered-gif) {
            max-width: 100%;
          }
        `}
      </style>
    </Modal>
  )
}

RenderedModal.propTypes = {
  intro: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
