import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import classnames from 'classnames'

const PaymentOption = ({
  selected,
  title,
  price,
  items,
  onClick,
}) => (
  <button
    type="button"
    className={classnames('payment-option', { '-selected': selected })}
    onClick={onClick}
  >
    <span className="title">{ title }</span>
    {items.map((item) => <span className="item" key={item}>{ item }</span>)}
    <span className="price">
      Available for
      {' '}
      <b>
        $
        { price }
      </b>
    </span>
    <style jsx>
      {`
        .payment-option {
          display: inline-flex;
          max-width: 35%;
          flex-direction: column;
          align-items: center;
          border: 3px solid white;
          padding: 0.9em;
          width: 100%;
          cursor: pointer;
          background: transparent;
          color: white;
          transition: background-color 0.3s ease, box-shadow 0.3s ease;
          outline: none;

          @media screen and (max-width: 768px) {
            max-width: none;
          }

          &.-selected {
            background-color: #333333;
            box-shadow: 0 0 20px #eeeeee;
          }

          .title {
            font-size: 1.6em;
            font-weight: bold;
            margin-bottom: 0.3em;
          }

          .item {
            font-size: 1.2em;
          }

          .price {
            margin-top: 0.5em;
            font-size: 1.4em;
          }
        }
        `}
    </style>
  </button>
)

PaymentOption.propTypes = {
  selected: PropTypes.bool,
  title: PropTypes.string,
  price: PropTypes.number,
  items: PropTypes.array,
  onClick: PropTypes.func,
}

const PAYMENT_OPTIONS = [
  {
    title: 'HD Video',
    price: 10,
    items: [
      '1280 x 720',
      'MP4 File',
      'No Watermark',
    ],
  },
  {
    title: 'Full HD Video',
    price: 15,
    items: [
      '1920 x 1080',
      'MP4 File',
      'No Watermark',
    ],
  },
]

export default function PaymentOptions({ iframeRef }) {
  const [selectedOption, setSelectedOption] = useState(PAYMENT_OPTIONS[1])

  const selectOption = useCallback((option) => {
    iframeRef.current.contentWindow.postMessage({
      action: 'setAmount',
      payload: option.price,
    }, '*')

    setSelectedOption(option)
  }, [])

  return (
    <Box display="flex" width="100%" justifyContent="center" gap={2}>
      {PAYMENT_OPTIONS.map((option) => (
        <PaymentOption
          key={option.title}
          selected={option.title === selectedOption.title}
          onClick={() => selectOption(option)}
          {...option}
        />
      ))}
    </Box>
  )
}

PaymentOptions.propTypes = {
  iframeRef: PropTypes.object.isRequired,
}
