import React from 'react'

export default function ProductHuntBadge() {
  return (
    <div className="product-hunt-badge">
      <a href="https://www.producthunt.com/posts/the-last-of-us-intro-creator?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-the&#0045;last&#0045;of&#0045;us&#0045;intro&#0045;creator" target="_blank" rel="noreferrer">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=383550&theme=light" alt="The&#0032;Last&#0032;of&#0032;Us&#0032;Intro&#0032;Creator - Create&#0032;your&#0032;own&#0032;customized&#0032;The&#0032;Last&#0032;of&#0032;Us&#0032;intro | Product Hunt" width="250" height="54" />
      </a>
      <style jsx>
        {`
        .product-hunt-badge {
          position: fixed;
          bottom: 10px;
          left: 16px;

          > .link > .image {
            width: 250px;
            height: 54px;
          }

          @media screen and (max-width: 1365px) {
            position: initial;
            display: flex;
            justify-content: center;
            padding-top: 16px
          }
        }
        `}
      </style>
    </div>
  )
}
