import useSWR, { mutate } from 'swr'

import introApiRequest from './intro-api-request'

export const getIntroAPIPath = (code) => `/api/the-last-of-us-intros/-${code}`

export const getIntro = async (code) => {
  try {
    const response = await introApiRequest({
      path: getIntroAPIPath(code),
    })

    return response.data
  } catch (error) {
    if (error.response?.status === 404) {
      return {
        notFound: true,
      }
    }
    throw error
  }
}

export const createIntro = async (intro) => {
  const response = await introApiRequest({
    path: 'api/the-last-of-us-intros',
    method: 'POST',
    data: intro,
  })

  // Update SWR Cache
  const code = response.data.slice(1)
  const path = getIntroAPIPath(code)
  mutate(path, {
    ...intro,
    status: 'created',
    video: null,
  })

  return code
}

export default function useIntroData(code = 'default') {
  const { data, error } = useSWR(getIntroAPIPath(code))
  return {
    data: {
      ...data,
      code,
    },
    error,
    loading: !error && !data,
  }
}
