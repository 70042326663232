import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Link } from '@mui/material'

import Modal from './Modal'
import PaymentOptions from './PaymentOptions'

export default function PaymentModal({
  code,
  open,
  onClose,
  onPaymentComplete,
}) {
  const iframeRef = useRef(null)

  useEffect(() => {
    const paymentEventHandler = (event) => {
      if (!iframeRef.current) {
        return
      }

      const iframeURL = iframeRef.current.src.split('?')[0]
      if (!iframeURL.startsWith(event.origin)) {
        return
      }

      const { data } = event
      if (data.type !== 'payment') {
        return
      }

      if (data.action === 'success') {
        onPaymentComplete(data.payload)
      }
    }

    window.addEventListener('message', paymentEventHandler)
    return () => {
      window.removeEventListener('message', paymentEventHandler)
    }
  }, [])

  return (
    <Modal
      title="Download Video"
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <Box px={3} pb={2} maxWidth="700px">
        <p>
          Great! You can choose the following option for your video:
        </p>
        <Box display="flex" gap={1} justifyContent="center">
          <PaymentOptions
            iframeRef={iframeRef}
          />
        </Box>
        <p>
          The videos usually takes up to 20 minutes to be delivered.
        </p>
        <p>
          We can also make further customizations like changing colors in the
          video, the background, the music, font type, etc.
          If you want to make more customizations please email us:&nbsp;
          <Link href="mailto:thelastofus@kassellabs.io?subject=I would like a custom The Last of Us intro" variant="link">
            thelastofus@kassellabs.io
          </Link>
        </p>
        <Box width="100%">
          <iframe
            ref={iframeRef}
            className="payment-iframe"
            title="Stripe Payment Form"
            src={`${process.env.PAYMENT_PAGE_URL}?embed=true&app=the-last-of-us&code=${code}&amount=1500`}
            allowpaymentrequest="true"
          />
        </Box>
        <p>
          After the payment, you will receive the confirmation and the video
          through your email.
        </p>
      </Box>
      <style jsx>
        {`
        .payment-iframe {
          border: none;
          width: 100%;
          height: 510px;

          @media screen and (max-width: 768px) {
            height:722px;
          }
        }
        `}
      </style>
    </Modal>
  )
}

PaymentModal.propTypes = {
  code: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onPaymentComplete: PropTypes.func,
}
