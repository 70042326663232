import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'

import Button from './Button'
import useNavigator from './useNavigator'
import downloadScreenshotFromApi from './downloadScreenshotFromApi'
import hasTextChanged from './hasTextChanged'

const downloadImage = async (intro, useLocationHref) => {
  await downloadScreenshotFromApi(intro, useLocationHref)
}

export default function DownloadImageButton({ intro, currentFormValues, alertTextChanged }) {
  const { isChromeIos } = useNavigator()
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false)

  const onDownloadImageClick = useCallback(async () => {
    if (hasTextChanged(intro, currentFormValues)) {
      alertTextChanged()
      return
    }

    setIsTakingScreenshot(true)
    const useLocationHref = isChromeIos
    await downloadImage(intro, useLocationHref)
    setIsTakingScreenshot(false)
  }, [intro, isChromeIos])

  return (
    <Button
      loading={isTakingScreenshot}
      variant="contained"
      onClick={onDownloadImageClick}
      startIcon={<PhotoCameraIcon />}
    >
      Download Image
    </Button>
  )
}

DownloadImageButton.propTypes = {
  intro: PropTypes.object,
  currentFormValues: PropTypes.object,
  alertTextChanged: PropTypes.func,
}
