const hasTextChanged = (oldTitles, newTitles) => {
  if (oldTitles.endingTitle !== newTitles.endingTitle) {
    return true
  }

  return oldTitles.titles.some(((oldTitle, index) => (
    oldTitle !== newTitles.titles[index]
  )))
}

export default hasTextChanged
